import React from 'react';
import Emoji from './Emoji';
import Model from '../models/Emoji';

interface Props
{
    emojis: Model[];
}

const Emojis: React.FC<Props> = ({ emojis }) =>
(
    <div className="emojis">
        {emojis.map((emoji, index) => <Emoji key={index} {...emoji} first={index === 0} />)}
    </div>
)

export default Emojis;

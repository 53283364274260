import Emoji from './models/Emoji';
import DB from './Database.json';

interface Category
{
    [subCategory: string]: Emoji[];
}

interface Index
{
    [category: string]: Category;
}

export default class Database
{
    public search = (query: string): Emoji[] =>
    {
        const matches: { [key: string]: Emoji } = {};

        return Object.keys(DB.search)
            .filter(key => key.includes(query.toLowerCase()))
            .flatMap(key => {
                const group = (DB.search as { [key: string]: Emoji[] })[key];
                return group.filter(emoji => {
                    if (matches[emoji.name]) {
                        return false;
                    }

                    matches[emoji.name] = emoji;
                    return true;
                });
            });
    }

    public index = (): Index =>
    {
        return DB.index;
    }
}

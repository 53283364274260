import React, { useState } from 'react';
import Model from '../models/Emoji';

function copyToClipboard(text: string): void
{
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

const Emoji: React.FC<Model> = ({ name, value, first }) =>
{
    const [animationClassName, setAnimationClassName] = useState('');

    const click = (): void =>
    {
        setAnimationClassName('pulse');
        copyToClipboard(value);
        setTimeout(() => {
            setAnimationClassName('');
        }, 1000);
    }

    return (
        <button type={first ? "submit" : "button"} className={"emoji " + animationClassName} onClick={click}>
            <span role="img" title={name} aria-label={name}>{value}</span>
        </button>
    );
}

export default Emoji;

import React, { useState } from 'react';
import Search from './components/Search';
import Index from './components/Index';
import './App.css';

const App: React.FC = () =>
{
    const [term, setTerm] = useState('');

    const search = (event: React.ChangeEvent<HTMLInputElement>): void =>
    {
        setTerm(event.target.value);
    }

    const emojis = term.length > 1
        ? <Search term={term} />
        : <Index />;

    return (
        <div>
            <form onSubmit={event => {
                event.preventDefault();
                return false;
            }}>
                <div className="search-bar">
                    <input placeholder="search" autoFocus onChange={search} />
                </div>
                {emojis}
            </form>
        </div>
    );
}
export default App;

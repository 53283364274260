import React from 'react';
import Emojis from './Emojis';
import Database from '../Database';

interface Props
{
    term: string;
}

const db = new Database();

const Search: React.FC<Props> = ({ term }) =>
(
    <Emojis emojis={db.search(term)} />
)

export default Search;

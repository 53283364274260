import React from 'react';
import Database from '../Database';
import Emojis from './Emojis';

const db = new Database();

const Index: React.FC = () =>
(
    <div className="index">
        {Object.entries(db.index()).map(([category, subCategories]) => {
            return (
                <div key={category} className="category">
                    <h2>{category}</h2>
                    {Object.entries(subCategories).map(([subCategory, emojis]) => {
                        return (
                            <div key={subCategory} className="sub-category">
                                <h3 key={subCategory}>{subCategory}</h3>
                                <Emojis emojis={emojis} />
                            </div>
                        );
                    })}
                </div>
            );
        })}
    </div>
)

export default Index;
